import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { eventList } from '123rf-ui-core/config';
import { serverTimeToUtc } from '123rf-ui-core/utils';
import { useHeightContext } from 'contexts/PricingPlanHeight';

// Pre-defined Components
import {
    PLAN_DURATION,
    PLAN_TYPE_PLUS,
    PLAN_TYPES,
    PLANS_WITH_PROMOCODE_PRICING,
    COUNTRIES_WITHOUT_AUTORENEWAL,
    COUNTRIES_WITHOUT_PLUS_FREE_TRIAL,
} from 'config/products';
import { PAGE_FOLDERS, DEFAULT_STATE_TEXT_VALUE as stateDefault } from 'config/constants';
import { PAYMENT_METHOD } from 'config/payments';

// Sub Components
import PlanOptionItem from 'components/Products/NewAffordablePlans/Shared/PlanOptionItem/index';
import PlusPlanDetails from './PlusPlanDetails';

const PlusPlan = (props) => {
    const {
        isPricingCardButtonClicked,
        setIsPricingCardButtonClicked,
        products,
        showPlusFreeTrial,
        setIsPlusFreeTrialSelected,
        isPlusBusinessActive,
        CommonDetails,
        GTM,
        WindowDimension,
        User,
    } = props;

    const { plusPlanRef } = useHeightContext();
    const { locale, countryOrigin: co, promoCode } = CommonDetails;
    const { isDesktopView, isTabletView, isMobileView } = WindowDimension;
    const { userBalance } = User;
    const countryOrigin = locale?.country?.code || co;
    const hasNoPlusFreeTrial = COUNTRIES_WITHOUT_AUTORENEWAL.includes(countryOrigin) || COUNTRIES_WITHOUT_PLUS_FREE_TRIAL.includes(countryOrigin);
    const DEFAULT_SELECTED_PLAN = hasNoPlusFreeTrial
        ? PLAN_TYPE_PLUS[0]
        : PLAN_TYPE_PLUS[1];

    const [selectedPlan, setSelectedPlan] = useState(DEFAULT_SELECTED_PLAN);
    // Local States
    const [plusPlanOptions, setPlusPlanOptions] = useState(
        products?.subs
            ?.filter(
                (prod) =>
                    (hasNoPlusFreeTrial
                        ? [PLAN_TYPE_PLUS[2], PLAN_TYPE_PLUS[0]]
                        : PLAN_TYPE_PLUS
                    ).includes(prod?.plan) &&
                    (showPlusFreeTrial || prod?.plan !== PLAN_TYPE_PLUS[1]),
            )
            .sort((prodOne, prodTwo) => {
                return prodOne.duration - prodTwo.duration;
            }) || [],
    );

    const handleOptionSelection = (selected) => {
        if (selectedPlan !== selected) {
            setSelectedPlan(selected);
            setIsPlusFreeTrialSelected(selected === PLAN_TYPE_PLUS[1]); // free trial - fplus7

            const planduration =
                selected.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
            const planVariant = `${(selected.indexOf('12') >= 0
                ? selected.substring(0, selected.length - 2)
                : selected.substring(0, selected.length - 1)
            ).replace(/^\w/, (c) => c.toUpperCase())}`;
            const planPrice =
                plusPlanOptions.filter((o) => o?.plan === selected)[0]?.price?.rounded_price || 0;
            const planType = selected.replace(/\d+/, '');
            const planTypeDesc =
                planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${planTypeDesc} — ${planduration} ${
                                    planduration > 1 ? 'Months' : 'Month'
                                }`,
                                id: selected,
                                price: planPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Subscription',
                                variant: `${planVariant} Subscription`,
                                quantity: 1,
                                metric1: 0,
                                metric2: 0,
                                metric3: planduration,
                            },
                        ],
                    },
                },
            });
        }
    };
    const eventPromocode = () => {
        const now = new Date();
        const start = eventList.eventPromo.start
            ? serverTimeToUtc(eventList.eventPromo.start)
            : now;
        const end = serverTimeToUtc('2023-11-27 23:59:59');
        const isWithinRange = now >= start && now < end;
        const promocode = isWithinRange && selectedPlan !== PLAN_TYPE_PLUS[1] ? '123RF50' : '';
        return promocode;
    };

    useEffect(() => {
        if (!hasNoPlusFreeTrial) {
            const filteredSubs = products?.subs
                ?.filter(
                    (prod) =>
                        (hasNoPlusFreeTrial
                            ? [PLAN_TYPE_PLUS[2], PLAN_TYPE_PLUS[0]]
                            : PLAN_TYPE_PLUS
                        ).includes(prod?.plan) &&
                        (showPlusFreeTrial || prod?.plan !== PLAN_TYPE_PLUS[1]),
                )
                .sort((prodOne, prodTwo) => {
                    return prodOne.duration - prodTwo.duration;
                });
            const sortedSubs = filteredSubs?.sort(
                (prodOne, prodTwo) => prodOne.duration - prodTwo.duration,
            );
            setPlusPlanOptions(sortedSubs || []);
            setSelectedPlan(!showPlusFreeTrial ? PLAN_TYPE_PLUS[0] : PLAN_TYPE_PLUS[1]);
            setIsPlusFreeTrialSelected(showPlusFreeTrial && selectedPlan === PLAN_TYPE_PLUS[1]);
        }
    }, [showPlusFreeTrial]);

    useEffect(() => {
        if (isPricingCardButtonClicked !== undefined) {
            const goToCheckoutPage = () => {
                const planduration =
                    selectedPlan.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
                const planVariant = `${(selectedPlan.indexOf('12') >= 0
                    ? selectedPlan.substring(0, selectedPlan.length - 2)
                    : selectedPlan.substring(0, selectedPlan.length - 1)
                ).replace(/^\w/, (c) => c.toUpperCase())}`;
                const planPrice =
                    plusPlanOptions.filter((o) => o?.plan === selectedPlan)[0]?.price
                        ?.rounded_price || 0;
                const planType = selectedPlan.replace(/\d+/, '');
                const planTypeDesc =
                    planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

                const eventPromoCode = eventPromocode() || promoCode;

                GTM.pushGTMData({
                    event: 'ecommerce_detail',
                    ecommerce: {
                        detail: {
                            products: [
                                {
                                    name: `${planTypeDesc} — ${planduration} ${
                                        planduration > 1 ? 'Months' : 'Month'
                                    }`,
                                    id: selectedPlan,
                                    price: planPrice,
                                    currency: locale.currency.code,
                                    brand: 'Online',
                                    category: 'Subscription',
                                    variant: `${planVariant} Subscription`,
                                    quantity: 1,
                                    metric1: 0,
                                    metric2: 0,
                                    metric3: planduration,
                                },
                            ],
                        },
                    },
                });

                GTM.pushGTMData({
                    event: 'BuyNowSubscriptionPlanClicked',
                    category: 'PricingPageCTA',
                    label: 'Subscription',
                    action: 'Plan Type',
                });

                const matchedPlan =
                    PLANS_WITH_PROMOCODE_PRICING.find((plan) => plan.plan === selectedPlan) || '';

                if (
                    countryOrigin === 'KR' &&
                    (stateDefault.paymentMethodKR === PAYMENT_METHOD.xpay ||
                        ((isMobileView || isTabletView) && process.env.KGINICIS_MOBILE === 'OFF'))
                ) {
                    const checkoutURL = `${
                        window.location.origin
                    }/checkout-v2/xpay/xpay_payment_request.php?pkg=subs&splan=${selectedPlan}${
                        eventPromoCode
                            ? `&promocode=${eventPromoCode}`
                            : matchedPlan && `&promocode=${matchedPlan.promocode}`
                    }`;
                    window.location.href = checkoutURL;
                } else {
                    const checkoutURL = `/${
                        PAGE_FOLDERS.checkout
                    }/?pkg=subs&plan=${selectedPlan}&po=pricing${
                        selectedPlan === 'fplus7'
                            ? `&promocode=FREEPLUS`
                            : eventPromoCode
                                ? `&promocode=${eventPromoCode}`
                                : matchedPlan && `&promocode=${matchedPlan.promocode}`
                    }`;
                    Router.push(checkoutURL).then(() => window.scrollTo(0, 0));
                }
            };

            const goToContactPage = () => {
                window.open('/contact/', '_blank');
            };

            if (userBalance?.plus > 0 && isPlusBusinessActive) {
                goToContactPage();
                setIsPricingCardButtonClicked(undefined);
            } else {
                goToCheckoutPage();
            }
        }
    }, [isPricingCardButtonClicked]);

    return (
        <section className="PlusPlan__wrapper">
            <div className="PlusPlan__subWrapper" ref={plusPlanRef}>
                {plusPlanOptions.map((spo) => {
                    return (
                        <PlanOptionItem
                            key={spo?.plan}
                            cardType={PLAN_TYPES.PLUS}
                            data={spo}
                            selectedPlan={selectedPlan}
                            handleOptionSelection={handleOptionSelection}
                            {...props}
                            {...WindowDimension}
                        />
                    );
                })}
            </div>
            <PlusPlanDetails {...props} />

            <style jsx>
                {`
                    .PlusPlan {
                        &__wrapper {
                            width: 100%;
                        }

                        &__subWrapper {
                            min-height: ${isDesktopView
                                ? '390px'
                                : isTabletView
                                ? '215px'
                                : 'initial'};
                            display: flex;
                            flex-direction: column;
                            row-gap: 8px;
                            column-gap: 11px;
                        }
                    }
                `}
            </style>
        </section>
    );
};

PlusPlan.propTypes = {
    isPricingCardButtonClicked: PropTypes.bool,
    setIsPricingCardButtonClicked: PropTypes.func,
    products: PropTypes.objectOf(PropTypes.shape),
    showPlusFreeTrial: PropTypes.bool,
    setIsPlusFreeTrialSelected: PropTypes.func.isRequired,
    isPlusBusinessActive: PropTypes.bool,
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    GTM: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    User: PropTypes.objectOf(PropTypes.shape).isRequired,
};

PlusPlan.defaultProps = {
    isPricingCardButtonClicked: undefined,
    setIsPricingCardButtonClicked: () => null,
    isPlusBusinessActive: false,
    products: null,
    showPlusFreeTrial: false,
};

export default PlusPlan;
