import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import intl from 'react-intl-universal';

// Sub Component
import { svg } from '123rf-ui-core/assets';

// Dynamic Component
const Tooltips = dynamic(() => import('123rf-ui-core/components').then((mod) => mod.Tooltips));

const AccessToAISuite = ({ tooltipId }) => { 
  return ( 
    <div className="PlanDetails__container--tooltip">
      <span>{intl.get('PRODUCTS.PlusAccessToAISuite')}</span>
      <img
          id={ tooltipId }
          className="PlanDetails__container--tooltipImage"
          alt="information"
          src={svg.information}
          width={16}
          height={16}
      />

      <Tooltips
          innerClassName="PlanDetails__container--innerTooltip"
          target={ tooltipId }
          position="bottom"
      >
          <div className="PlanDetails__tooltip--container">
              <span className="PlanDetails__tooltip--header">
                  {intl.get('PRODUCTS.WhatsIncluded')}
              </span>
              <ul className="PlanDetails__tooltip--list">
                  <li>{intl.get('AI_IMAGE_GENERATOR')}</li>
                  <li>{intl.get('AI_WRITER')}</li>
                  <li>{intl.get('AI_REMOVAL_BG')}</li>
                  <li>{intl.get('AI_BG_REMIX')}</li>
                  <li>{intl.get('AI_IMAGE_EXTENDER')}</li>
                  <li>{intl.get('AI_IMAGE_UPSCALER')}</li>
                  <li>{intl.get('AI_BACKGROUND_BLUR')}</li>
                  <li>{intl.get('AI_FACE_SWAP')}</li>
                  <li>{intl.get('AI_GENERATIVE_FILL')}</li>
                  <li>{intl.get('AI_SKETCH')}</li>
              </ul>
          </div>
      </Tooltips>

      <style jsx global>
        {`
            .PlanDetails {
                &__container {
                    &--tooltip {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                    }

                    &--innerTooltip {
                        min-width: 200px;
                        max-width: 300px !important;

                        padding: 8px !important;
                        border-radius: 8px !important;
                    }

                    &--tooltipImage {
                        cursor: pointer;
                    }
                }

                &__tooltip {
                    &--container {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }

                    &--header {
                        color: #212121;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                    }

                    &--list {
                        margin-bottom: 0;

                        & > li {
                            color: #212121;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }
                    }
                }
            }
        `}
      </style>
    </div>
  );
};

AccessToAISuite.propTypes = {
    tooltipId: PropTypes.string.isRequired,
};

export default AccessToAISuite;
