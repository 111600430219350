import intl from 'react-intl-universal';

// Sub Component
import PlanDetailsItem from 'components/Products/NewAffordablePlans/Shared/PlanDetailsItem';
import AccessToAISuite from 'components/Products/NewAffordablePlans/Shared/AccessToAISuite';

const PlusBusinessPlanDetails = () => {
    const UIAccessToAISuite = (
        <AccessToAISuite tooltipId='ai-suite-plus-business-tooltip' />
    );

    return (
        <ul className="PlusBusinessPlanDetails__wrapper">
            <PlanDetailsItem item={UIAccessToAISuite} />
            <PlanDetailsItem item={intl.get('PRODUCTS.PlusUnlimitedDownloads')} />
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.StandardLicense')} type='link'/>
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.MultipleUsersGetPlusBusinessTeam')} type='link'/>

            <style jsx>
                {`
                    .PlusBusinessPlanDetails {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            margin-top: 16px;
                        }
                    }
                `}
            </style>
        </ul>
    );
};

export default PlusBusinessPlanDetails;
